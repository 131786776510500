import style from "./style.module.css";
import { useNavigation } from "../../pages/ContextMenu";
import { withTranslation, WithTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import Card from "../Card";
import photoImg from './assets/photo.svg';
import videoImg from './assets/video.svg';
import youtubeImg from './assets/youtube.svg';
import ImportPhoto from "./ImportPhoto";
import ImportVideo from "./ImportVideo";
import ImportYoutube from "./ImportYoutube";
import ShareMedia from "../ShareMedia";
import { createMedia, getMedia, updateMedia, useGetImages, useOneMedia } from "../../stores/Media";
import { UserContext } from "../../providers/UserProvider";
import { Media } from "../../models/Media";

interface ImportMediaProps extends WithTranslation {
    onReturn?: () => void;
    onChangeTitle?: (title: string) => void;
    initialData?: any;
}

const ImportMedia = ({ t, onReturn, onChangeTitle, initialData }: ImportMediaProps) => {
    const userDataProp = useContext(UserContext);
    const { subscribe } = useNavigation();
    const [type, setType] = useState(initialData?.type || '');
    const [step, setStep] = useState('IMPORT')
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (initialData?.id) {
                const media = await getMedia(userDataProp?.token as string, initialData.id);
                setData(media);
            }
        };
        fetchData();
    }, [initialData]);

    useEffect(() => {
        const handleReturn = () => {
            if (type) {
                setData(null);
                setType('');
                return;
            }
            if (onReturn) onReturn();
        };

        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [type]);

    useEffect(() => {
        if (type === 'IMAGE' && onChangeTitle) {
            onChangeTitle(t('Importer des photos'));
        } else if (type === 'VIDEO' && onChangeTitle) {
            onChangeTitle(t('Importer une vidéo'));
        } else if (type === 'YOUTUBE' && onChangeTitle) {
            onChangeTitle(t('Importer une vidéo Youtube'));
        } else if (onChangeTitle) {
            onChangeTitle('');
        }
    }, [type]);

    const card = (img: string, title: string, action: () => void) => (
        <Card margin={false} action={action}>
            <div className={style.card}>
                <div className={style.img}>
                    <img src={img} alt={title} />
                </div>
                <div className={style.text}>
                    <div className={style.cardTitle}>{t(title)}</div>
                </div>
            </div>
        </Card>
    );

    const handleImport = (data: any) => {
        setData(data);
        setStep('SHARE');
    };
    
    const handleShare = async (sharingLists: any) => {
        if (!userDataProp?.token) return;
        data?.forEach(async (element: Media) => {
            const media: any = {
                title: element.title,
                type: type,
                url: element.url,
                capturedOn: element.captured_on,
                capturedPlace: element.captured_place,
                anecdote: element.anecdote,
                isVisible: true,
                ...(element.thumbnail && { thumbnail: element.thumbnail }),
                familyList: sharingLists.familyList,
                proList: sharingLists.proList.map((pro: any) => ({
                    id: pro,
                    canWrite: true,
                })),
            }

            if (initialData) {
                await updateMedia(userDataProp.token as string, initialData.id, media);
            } else {
                await createMedia(userDataProp.token as string, media);
            }
            if (onReturn) onReturn();
        });
    };

    if (step.startsWith('SHARE')) return <ShareMedia onReturn={() => setStep('IMPORT')} onChangeTitle={onChangeTitle} onShare={(data) => handleShare(data)} />;

    if (step === 'IMPORT') {
        if (type === 'IMAGE') return <ImportPhoto data={data} onReturn={() => setType('')} onChangeTitle={onChangeTitle} onImport={(data) => handleImport(data)} />;

        if (type === 'VIDEO') return <ImportVideo data={data} onReturn={() => setType('')} onChangeTitle={onChangeTitle} onImport={(data) => handleImport(data)} />;

        if (type === 'YOUTUBE') return <ImportYoutube data={data} onReturn={() => setType('')} onChangeTitle={onChangeTitle} onImport={(data) => handleImport(data)} />;

        return (
            <>
                {!type && <>
                    <div className={style.subTitle}>{t('Sélectionnez le type de média que vous voulez importer dans Stimul’in')}</div>
                    <div className={style.cardContainer}>
                        {card(photoImg, 'Photo', () => setType('IMAGE'))}
                        {card(videoImg, 'Vidéo', () => setType('VIDEO'))}
                        {card(youtubeImg, 'Vidéo Youtube', () => setType('YOUTUBE'))}
                    </div>
                </>}
            </>
        );
    };

    return null;
};

export default withTranslation()(ImportMedia);