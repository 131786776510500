import axios from 'axios';
import { useEffect, useState } from 'react';
import { ApiResponse, Media, MediaItem } from '../models/Media';
import { API_URL } from '../config';

const url = API_URL;

export const useMedia = (token: string) => {
    const [data, setData] = useState<MediaItem[] | []>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<ApiResponse>(`${url}/api/media/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(Array.isArray(response.data.data) ? response.data.data : []);
            } catch (error) {
                setError(error instanceof Error ? error : new Error('An error occurred'));
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token]);

    return { data, loading, error };
};

export const useOneMedia = (mediaId: string, token: string) => {
    const [data, setData] = useState<MediaItem | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchMedia = async () => {
            try {
                setLoading(true);
                const response = await axios.get<ApiResponse>(`${url}/api/media/${mediaId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setData(response.data.data.length > 0 ? response.data.data[0] : null);
            } catch (err) {
                setError(error instanceof Error ? error : new Error('An error occurred'));
            } finally {
                setLoading(false);
            }
        };

        if (mediaId) {
            fetchMedia();
        }
    }, [mediaId, token]);

    return { data, loading, error };
};

export const useGetVideosAndYoutube = (token: string) => {
    const [data, setData] = useState<MediaItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchMedia = async () => {
        try {
            setLoading(true);
            const video = await axios.get(`${url}/api/media/video`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const youtube = await axios.get(`${url}/api/media/youtube`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setData([...video.data.data, ...youtube.data.data]);
        } catch (err) {
            setError(error instanceof Error ? error : new Error('An error occurred'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token) fetchMedia();
    }, [token]);

    return { data, loading, error, refetch: fetchMedia };
};

export const useGetImages = (token: string) => {
    const [data, setData] = useState<MediaItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchMedia = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${url}/api/media/image`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        } catch (err) {
            setError(error instanceof Error ? error : new Error('An error occurred'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token) fetchMedia();
    }, [token]);

    return { data, loading, error, refetch: fetchMedia };
};

export const getMedia = async (
    token: string,
    id: string = 'all',
): Promise<any> =>
    axios
        .get(`${url}/api/media/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data as Media;
        })
        .catch((error: any) => {
            console.error(error);
            return null;
        });

export const createMedia = async (token: string, media: Media) => {
    try {
        const response = await axios.post(`${url}/api/media`, media, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data as Media['id'];
    } catch (error) {
        throw error instanceof Error ? error : new Error('An error occurred');
    }
}

export const deleteMedia = async (token: string, mediaId: string) => {
    try {
        const response = await axios.delete(`${url}/api/media/${mediaId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error instanceof Error ? error : new Error('An error occurred');
    }
}

export const updateMedia = async (token: string, mediaId: string, media: Media) => {
    try {
        const response = await axios.put(`${url}/api/media/${mediaId}`, media, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error instanceof Error ? error : new Error('An error occurred');
    }
}
