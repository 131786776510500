import { WithTranslation, withTranslation } from "react-i18next";
import style from './style.module.css';

import { useForm } from 'react-hook-form';
import FormGroup from "../FormGroup";
import Button from "../Button/index";
import ButtonsContainer from "../ButtonsContainer";
import { useContext, useEffect, useState } from "react";
import { useNavigation } from "../../pages/ContextMenu";
import { MediaItem } from "../../models/Media";
import { useGetImages, useGetVideosAndYoutube } from "../../stores/Media";
import { UserContext } from "../../providers/UserProvider";
import UploadInput from "../UploadInput";
import Card from "../Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Input from "../Input/index";
import QuizzImg from '../../assets/quizz.svg?react';
import DuoImg from '../../assets/duo.svg?react';
import PuzzleImg from '../../assets/puzzle.svg?react';
import ReordonImg from '../../assets/reordon.svg?react';

interface CreateFormProps extends WithTranslation {
    onReturn: () => void;
    onCreate: (data: any) => void;
    onChangeTitle?: (title: string) => void;
    data: any;
    type: string;
}

const MediaGrid = ({ medias, onSelect, selectedMedia }: { medias: MediaItem[], onSelect: (media: MediaItem) => void, selectedMedia: MediaItem[] }) => {
    return (
        <div className={style.grid}>
            {medias?.map((media) => (
                <div
                    key={media.id}
                    className={style.elemContainer}
                    onClick={() => onSelect(media)}
                >
                    <Card padding={false} margin={false} className={style.elemContent}>
                        <div className={`${style.box} ${selectedMedia?.map((e) => e.id).includes(media.id) ? style.selected : ''}`}>
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <div className={style.imgContainer}>
                            <img className={style.image} src={media.thumbnail || media.url} />
                        </div>
                    </Card>
                </div>
            ))}
        </div>
    );
}

const CreateForm = ({ t, onReturn, onChangeTitle, type, onCreate, data: toEdit }: CreateFormProps) => {
    const userDataProp = useContext(UserContext);
    const { data: medias } = useGetImages(userDataProp?.token || '');
    const { data: videos } = useGetVideosAndYoutube(userDataProp?.token || '');
    const [state, setState] = useState('');
    const { subscribe } = useNavigation();
    const {
        watch,
        register,
        setValue,
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<{ game: any, photos: MediaItem[], toUpload: MediaItem[] }>({
        mode: 'all',
        defaultValues: {
            game: {},
            photos: [],
            toUpload: [],
        },
    });

    useEffect(() => {
        if (toEdit) {
            setValue('game', toEdit);
            setValue('photos', toEdit.mediaList);
        }
    }, [toEdit]);

    const GameTitle: { [key: string]: string } = {
        'QUIZ': 'Bon titre',
        'MEMORIZ': 'Duo',
        'PUZZLE': 'En morceaux',
        'ORDER': `Un peu d'ordre`,
    }

    const GameImg: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
        'QUIZ': QuizzImg,
        'MEMORIZ': DuoImg,
        'PUZZLE': PuzzleImg,
        'ORDER': ReordonImg,
    }

    const nbPhotos: { [key: string]: number } = {
        'QUIZ': 6,
        'MEMORIZ': 18,
        'PUZZLE': 1,
        'ORDER': 1,
    }

    useEffect(() => {
        const handleReturn = () => {
            if (state === 'DETAILS') {
                setState('REWARD');
                return;
            } else if (state === 'VIDEO') {
                setValue('game.video', null);
                setState('REWARD');
                return;
            } else if (state !== '') {
                setState('');
                return;
            }
            if (onReturn) onReturn();
        };

        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [state]);

    const onSubmit = (data: any) => {
        onCreate(data);
    };

    const photos = watch('photos') || [];
    const toUpload = watch('toUpload') || [];
    const photosLength = photos.length + watch('toUpload').length;
    const title = watch('game.title');
    const video = watch('game.video');

    const createdGame = (Img: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) => (
        <Card margin={false}>
            <div className={style.card}>
                <Img className={style.img} />
                <div className={style.text}>
                    <div className={style.cardTitle}>{title || GameTitle[type]}</div>
                </div>
            </div>
        </Card>
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={style.root}>
                    {state === '' && <>
                        <div className={style.subTitle}>{t('Choisissez {{count}} photo', { count: nbPhotos[type] })}</div>
                        <FormGroup>
                            <UploadInput
                                control={control}
                                register={register('toUpload')}
                                pathStorage="photos"
                                multiple={true}
                                selected={true}
                                type="IMAGE"
                            />
                            <MediaGrid
                                medias={medias}
                                onSelect={(media) => {
                                    if (photos.map((photo) => photo.id).includes(media.id)) {
                                        setValue('photos', photos.filter((photo: MediaItem) => photo.id !== media.id));
                                    } else if (photosLength < nbPhotos[type]) {
                                        setValue('photos', [...photos, media]);
                                    }
                                }}
                                selectedMedia={watch('photos') || []}
                            />
                        </FormGroup>
                        {photosLength > nbPhotos[type] && <p className={style.error}>{t('Vous avez importer trop de photos. Veuillez en retirer.')}</p>}
                        <ButtonsContainer className={style.buttonsContainer} position="CENTER">
                            <Button size='LARGE' theme='SECONDARY' disabled={!isValid || photosLength !== nbPhotos[type]} action={() => type === 'QUIZZ' ? setState('TITLES') : setState('REWARD')} label={t('Suivant')} />
                        </ButtonsContainer>
                    </>}

                    {state === 'TITLES' && <>
                        <div className={style.subTitle}>{t('Personnalisez les titres')}</div>
                        <div>{t('Ils servent de réponse et sont indispensables dans le jeu.')}</div>
                        {toUpload.map((photo, index) => (
                            <FormGroup
                                key={photo.id}
                            >
                                <div className={style.inputContainer}>
                                    <div className={style.imgPreview}>
                                        <img src={photo.url} alt={photo.title} />
                                    </div>
                                    <Input
                                        register={register(`photos.${index}.title`)}
                                        type={'text'}
                                        placeholder={t('Ajouter une réponse') as string}
                                    />
                                </div>
                            </FormGroup>
                        ))}
                        {photos.map((photo, index) => (
                            <FormGroup
                                key={photo.id}
                            >
                                <div className={style.inputContainer}>
                                    <div className={style.imgPreview}>
                                        <img src={photo.url} alt={photo.title} />
                                    </div>
                                    <Input
                                        register={register(`photos.${index}.title`)}
                                        type={'text'}
                                        placeholder={t('Ajouter une réponse') as string}
                                    />
                                </div>
                            </FormGroup>
                        ))}
                        <ButtonsContainer className={style.buttonsContainer} position="CENTER">
                            <Button size='LARGE' theme='SECONDARY' action={() => setState('REWARD')} label={t('Suivant')} />
                        </ButtonsContainer>
                    </>}

                    {state === 'VIDEO' && <>
                        <div className={style.subTitle}>{t('Choisissez 1 vidéo')}</div>
                        <FormGroup>
                            <MediaGrid
                                medias={videos}
                                onSelect={(media) => setValue('game.video', media)}
                                selectedMedia={video ? [video] : []}
                            />
                        </FormGroup>
                        <ButtonsContainer className={style.buttonsContainer} position="CENTER">
                            <Button size='LARGE' theme='SECONDARY' action={() => setState('REWARD')} label={t('Suivant')} />
                        </ButtonsContainer>
                    </>}

                    {state === 'REWARD' && <>
                        <div className={style.subTitle}>{t('Personnalisez ces messages si vous le souhaitez')}</div>
                        <FormGroup label={t(`Message d'accueil - Optionnel`) as string}>
                            <Input
                                register={register('game.encouragementMessage')}
                                type="multiline"
                                placeholder={t('Écrivez un message qui sera affiché au début du jeu.') as string}
                            />
                        </FormGroup>
                        <div className={style.subTitle}>{t('Ajouter une vidéo surprise')}</div>
                        <ButtonsContainer className={style.buttonsContainer} position="CENTER">
                            <Button size='LARGE' theme='PRIMARY' action={() => setState('VIDEO')} label={t('Ajouter une vidéo')} />
                        </ButtonsContainer>
                        <FormGroup label={t(`Message de récompense - Optionnel`) as string}>
                            <Input
                                register={register('game.congratulationsMessage')}
                                type="multiline"
                                placeholder={t('Écrivez un message qui sera affiché à la fin du jeu.') as string}
                            />
                        </FormGroup>
                        <ButtonsContainer className={style.buttonsContainer} position="CENTER">
                            <Button size='LARGE' theme='SECONDARY' action={() => setState('DETAILS')} label={t('Suivant')} />
                        </ButtonsContainer>
                    </>}

                    {state === 'DETAILS' && <>
                        <div className={style.subTitle}>{t('Donnez un nom à votre jeu')}</div>
                        {createdGame(GameImg[type])}
                        <FormGroup label={t('Nom du jeu') as string} required>
                            <Input
                                register={register('game.title', { required: true })}
                                placeholder={t('Ex: Autoportrait') as string}
                            />
                        </FormGroup>
                        <ButtonsContainer className={style.buttonsContainer} position="CENTER">
                            <Button size='LARGE' theme='SECONDARY' disabled={!isValid} action={handleSubmit(onCreate)} label={t('Suivant')} />
                        </ButtonsContainer>
                    </>}
                </div>
            </form>
        </>
    );
};

export default withTranslation()(CreateForm);